.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  width: 33.33%;
  font-size: 20px;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.container-2 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}

.item {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.item button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: red;
  /* border: none; */
  color: white;
  /* font-size: 2rem; */
  opacity: 0;
  transition: opacity 0.3s ease;
  /* border-radius: 50%; */
  /* padding: 0.5rem; */
}

.item button:hover {
  background: darkred;
}

.item:hover button {
  opacity: 1;
}
